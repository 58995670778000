// 关于平台...企业信息列表接口
import http from "./http";
import path from "./path";

export default {
    // 付款
    payMoney(query={}) {  
        return http.post(path.chainordr + 'payMoney',query)
    },
    // 查询流水列表
    all(query={}) {  
        return http.post(path.chainordr + 'listByPage',query)
    },
    // 解冻 // 撤销付款
    confirmOrCancel(query={}) {  
        return http.post(path.chainordr + 'confirmOrCancel',query)
    },
    // 企业子账户余额
    
    selPtAmt(query={}) {  
        return http.post(path.chainordr + 'selPtAmt',query)
    },
    // 平台子账户余额
    selAccAmt(query={}) {  
        return http.post(path.chainordr + 'selAccAmt',query)
    },
    // 企业列表
    selSfEtc(query={}) {  
        return http.post(path.chainordr + 'selSfEtc',query)
    },
    // 操作状态同步
    synchronousStatus(query={}) {  
        return http.post(path.chainordr + 'synchronousStatus',query)
    },
    // 查询回单列表
    list(query={}) {  
        return http.post(path.chainordr + 'list',query)
    },
    // 查询分润
    selRelTraceFee(query={}) {  
        return http.post(path.chainordr + 'selRelTraceFee',query)
    },
}