<!-- 微信app页面 -->
<template>
    <div>
        <!-- 网站 -->
        <div class="conent">
            <img src="../../src/assets/wxindex.jpg" alt="">
        </div>
        <!-- 下载页面 -->
        <div class="condow">
            <!-- logo -->
            <div class="dowlogo">
                <img src="../../src/assets/xmlog.png" alt="">
            </div>
            <!-- 文字内容 -->
            <div class="dowcon">
                <p>“点击下方按钮，下载熊猫煤仓专属APP，为您的企业提供云交易、云管理、云账务、云物流、云金融、云官网等各种服务，为您的企业数字化发展提供助力！”</p>
            </div>
            <!-- 下载按钮 -->
            <!-- <div class="dowbut" @click="androids">Android版本下载</div> -->
            <div class="dowbuts" data-clipboard-text="https://scm.coalcloudtech.com/saas/saas-common/download?Signature=328b14094b22d98589a0b135efe853eae3cde83b53e8f7920265df1c88c6389f&AuthorizationId=wxda0b1c468b83bc99" @click="copy">点击生成链接</div>
            <div class="dowsize">安卓用户点击链接打开浏览器下载App</div>
            <!-- <div class="dowbut">IOS版本下载</div> -->
            <!-- 二维码 -->
            <!-- <div class="dowbottom">
                <div class="dowone">
                    <img src="../../src/assets/gzh.png" alt="">\
                    <p>关注公众号</p>
                </div>
                <div class="dowone">
                    <img src="../../src/assets/xcx.png" alt="">\
                    <p>识别小程序</p>
                </div>
                <div class="dowone">
                    <img src="../../src/assets/kf.png" alt="">\
                    <p>添加客服</p>
                </div>
            </div> -->
        </div>
        <div class="copyright-bottom">
            <div class="copyright"><span>Copyright © 山西海云启辰科技有限公司 版权所有</span><p><a target="_blank" href="https://beian.miit.gov.cn/">晋ICP备2022001434号-3A</a></p></div>
        </div>
    </div>
</template>
<script>
import Clipboard from 'clipboard';
export default{
    
    data(){
        return {
            
        }
    },
    created(){
        
    },
    mounted() {

    },
    methods:{
        	//点击复制的方法
			copy() {
				var clipboard = new Clipboard('.dowbuts')
				clipboard.on('success', e => {
                    this.$message({
                        showClose: true,
                        message: '复制成功,请在浏览器复制链接下载安卓APP', 
                        type: 'success'
                    });
					// 释放内存
					clipboard.destroy()
				})
				clipboard.on('error', e => {
					// 不支持复制
					console.log('该浏览器不支持自动复制')
					// 释放内存
					clipboard.destroy()
				})
			},
        // 下载Android
        androids(){
            let url = 'https://scm.coalcloudtech.com/saas/saas-common/download?Signature=328b14094b22d98589a0b135efe853eae3cde83b53e8f7920265df1c88c6389f&AuthorizationId=wxda0b1c468b83bc99';
            window.open(url)
        },
    },
}
</script>
<style lang="less" scoped> 
@media screen and (min-width:750px){
    .conent{
        width: 100vw;
        height: calc(100vh - 60px);
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            margin: auto;
        }
    }  
    .condow{
        display: none;
    }
    // 版权开始
    .copyright-bottom{
        width: 100%;
        height:60px;
        padding-top:10px;
        box-sizing: border-box;
        // background-color: #fff;
        .copyright{
        width:auto;
        height: 27px;
        margin: auto;
        text-align: center;
            span{
                font-size: 12px;
                line-height: 24px;
                color: #828282;
                
            }
            p{
                font-size: 12px;
                color: #828282;
            }
            a{
                font-size: 12px;
                color: #828282;
            }
        }
    }
    // 版权结束
}
@media screen and (max-width:750px) {
    // 版权开始
    .copyright-bottom{
        width: 100%;
        height:60px;
        padding-top:10px;
        box-sizing: border-box;
        background-color: #fff;
        .copyright{
        width:auto;
        height: 27px;
        margin: auto;
        text-align: center;
            span{
                font-size: 12px;
                line-height: 24px;
                color: #828282;
                
            }
            p{
                font-size: 12px;
                color: #828282;
            }
            a{
                font-size: 12px;
                color: #828282;
            }
        }
    }
    // 版权结束
    .conent{
        display: none;
    }    
    .condow{
        // width: 150rpx;
        height: calc(100vh - 60px);
        // overflow: hidden;
        padding:8% 5%;
        box-sizing: border-box;
        background-image: url(../assets/backimg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        .dowlogo{
            img{
                width: 200px;
                height: 70px;
            }
        }
        .dowcon{
            padding: 20px;
            box-sizing: border-box;
            p{
                font-size: 18px;
                line-height: 34px;
                color: #fff;
                font-weight: 600;
                text-indent: 2em;
            }
        }
        .dowbut{
            // display: inline-block;
            width: 250px;
            height: 60px;
            background: #fff;
            margin: 40px auto;
            padding: 12px;
            box-sizing: border-box;
            font-size: 24px;
            line-height: 38px;
            border-radius: 8px;
            text-align: center;
            font-weight: 600;
            cursor: pointer;
        }
        .dowbuts{
            width: 250px;
            // height: 40px;
            background: #fff;
            margin: 40px auto 6px;
            padding: 12px;
            box-sizing: border-box;
            font-size: 18px;
            line-height: 38px;
            border-radius: 8px;
            text-align: center;
            font-weight: 600;
            cursor: pointer;
        }
        .dowsize{
            width: 100%;
            font-size: 12px;
            color: #fff;
            text-align: center;
        }
        .dowbottom{
            display: flex;
            justify-content: space-evenly;
            margin-top:90px;
            .dowone{
                width: 80px;
                overflow: hidden;
                text-align: center;

                img{
                    width: 80px;
                    height: 80px;
                }
                p{
                    font-size: 12px;
                    color: #fff;
                    line-height: 18px;

                }
            }
        }
    }
}

</style>