// 用户访问表接口
import http from "./http";
import path from "./path";

export default {
    selVisisted(data={}) { 
        return http.post(path.busprolog + 'selVisisted',data)
    },
    add(data={}) {
        return http.post(path.busprolog + 'add',data)
    },

}