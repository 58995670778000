<template>
	<div class="comnent">
		
		<div class="comh1">熊猫煤仓隐私协议</div>
		<div class="time">更新日期：{{time}}</div>
		<span>熊猫煤仓平台是有山西海云启辰科技有限公司所有（以下简称“本公司”或熊猫煤仓），本公司非常注重保护用户（包括但不限于通过有线或移动设备访问本平台的个人及企业）的个人信息及隐私。鉴于您在使用我们的服务时，我们可能会收集和使用您的相关信息，为向您阐明用户信息收集、使用、共享、管理及保护的规则，特制定本《熊猫煤仓隐私政策》（以下或称“本政策”）。并特别提醒您：</span>
		<p>本政策与用户所使用的本平台服务信息相关，在使用熊猫煤仓各项产品或服务前，请务必仔细阅读并透彻理解本政策，在确认充分理解并同意后使用相关产品或服务。一旦您开始使用熊猫煤仓各项产品或服务，即表示您已充分理解并同意本政策。</p>
		<p>如对本政策或相关事宜有任何问题，请通过客服热线：13934568608与本平台联系。</p>
		<p>本政策将帮忙您了解以下内容：</p>
		<p>1.我们如何收集和使用您的个人信息</p>
		<p>2.我们如何共享、转让、公开披露您的个人信息</p>
		<p>3.我们如何保护您的个人信息</p>
		<p>4.我们如何保存您的个人信息</p>
		<p>5.您如何管理个人信息</p>
		<p>6.我们如何收集和处理未成年人的个人信息</p>
		<p>7.如何联系我们</p>
		<!-- <div class="title">一、适用范围</div>
		<span>本平台提供服务时，可能会收集和使用下列与用户有关的信息，如果用户不提供信息，可能无法成为本平台的会员或无法享受本平台提供的某些服务，或无法到达相关服务拟达到的效果</span>
		<div class="tables">
			<el-table :data="tableData" style="width:100%" border>
				<el-table-column prop="name" label="业务功能" width="80">
				</el-table-column>
				<el-table-column prop="address" label="收集个人信息">
				</el-table-column>
			</el-table>
		</div> -->
		<div class="title">一、用户信息的收集及使用</div>
			<p>本平台提供服务时，可能会收集、存储和使用下列与用户有关的信息，如果用户不提供相关信息，可能无法注册成为本平台的会员或无法享受本平台提供的某些服务，或者无法达到相关服务拟达到的效果。</p>
			<p style="font-weight: 700;">1.用户主动提供的信息  </p>
			<p>当您使用账号注册功能时，我们会收集由您主动提供给我们的一些单独或者结合识别您实名身份的信息，包括：手机号码、联系人姓名、验证码等。我们收集这些信息是用以完成的服务，并保护您的账号安全，您应知悉，手机号码和验证码匹配结果属于您的个人敏感信息，我们收集该类信息是为了满足相关法律法规的要求，如您拒绝提供可能导致您无法使用我们的部分功能，请您谨慎考虑后再提供。同时，您也可以在注册时或后续使用过程中填写或补充您的性别、昵称、头像，如您未填写或后续删除、变更此类信息的，不会影响注册及产品功能的正常使用。</p>
			
			<p style="font-weight: 700;">2.平台获取的用户信息</p>
			<p>2.1基于设备相册权限的附加业务功能</p>
			<p>当您上传/更新账号头像、保存合同图片，我们将需求获取您的设备相册权限，并收集您提供的图片、视频内容信息（个人信息），如您拒绝提供会使用无法使用该功能，并不影响您正常使用产品与/或服务的其他功能。当您取消授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依据法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。</p>	
			<p>2.2基于设备相册权限的附加业务功能</p>
			<p>当您拍摄功能、上传/更新账号头像、图文/视频制作与发布相关业务功能时（上传煤仓场景等），我们将需要获取您的设备相机权限，并收集您提供的图片、视频内容信息（个人信息）。如您拒绝提供会使用无法使用该功能，并不影响您正常使用产品与/或服务的其他功能。当您取消授权后，我们将不再收集该信息，也无法再为您提供上述与之对应的服务；但除非您依据法律的规定删除了您的个人信息，否则您的取消行为不会影响我们基于您之前的授权进行的个人信息的处理、存储。</p>
			<p>2.3基于名片业务功能</p>
			<p>当前使用名片功能时，您可以点更换头像获取您的微信头像，填写姓名/职位/公司/手机/地址等，及公司介绍中的logo、公司照片等，这些信息完全取决与您个人意愿填写，平台不作为强制要求。</p>
			<p>2.4个人中心功能</p>
			<p>当前使用我的页面时，可以进行头像更换，以及身份信息的完善，例如洗煤厂/贸易商等，个人昵称、企业名称、姓名、职位等。这些信息完全取决与您个人意愿填写，平台不作为强制要求。</p>
			<p>2.5客户管理</p>
			<p>当您需要使用我的页面客户管理维护您的客户关系时，可以进行新增公司名称、身份选择、姓名、联系方式等。这些信息完全取决与您个人意愿填写，平台不作为强制要求。</p>
			<p>2.6设备信息收集</p>
			<p>包括设备Mac地址（目的用于数据的安全校验）、唯一设备识别码（IMEI、IMSI）、设备硬件序列号、Android ID、设备型号、设备名称、设备标识和应用程序版本、接入网络的放手、网络质量数据、移动网络信息（包括运营商名称）、设备所在位置相关信息（您填写的地理位置信息）、产品版本号、获取安装应用列表信息。为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限并根据您的授权获取必要的相关信息。收集此类信息的目的是：Mac地址、IMEI、IMSI、设备序列号、Android ID、应用程序版本、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）等是为了记录当前手机设备的信息，在发生网络错误、程序错误或一些未知的Bug时，可以针对具体机型、网络状态、设备版本等有效信息进行软件的有效适配。</p>
			<p>2.7日志信息的收集</p>
			<p>当您使用我们的产品与/或服务时，为了避免您对服务存疑或出现软件Bug等原因，导致您无法正常使用时。我们会在您的关键操作节点收集您的个人操作记录。并作为有关操作日志、服务日志保存，包括您的下单记录、评论记录、浏览记录、分享/互动记录、收藏/关注/访问日期和时间，此类日志的收集只为提升系统的稳定性与友好交互性，不做其他用途。</p>
			<p>2.8通知权限</p>
			<p>为了您能及时收到重要信息，我们会给您发送通知（如新增企业/煤种、煤种价格变动等），我们将需要您授权通知的权限。因为当有与您相关的业务发生是，如何快速的通知到您个人并进行业务是我们设计管理软件的重中之重，在APP的桌面logo中也会带有醒目的提醒角标或标志。</p>
			<p>2.9获取已安装应用权限</p>
			<p>为了您能及时查看重要信息，我们会在APP的桌面logo上带有醒目的提醒角标或标志（如新增企业/煤种、煤种价格变动等），我们将需要您授予获取已安装应用的权限。因为当有与您相关的业务发生时，我们希望能让您快速及时获知并处理相关事件</p>
			<p>3.0应用自启动权限-Android权限</p>
			<p>1) android.permission.INTERNET （访问网络权限）：实现应用程序联网</p>
			<p>2) android.permission.GET_TASKS(获取任务信息权限）：允许应用获取当前或最近运行的应用</p>
			<p>3) android.permission. WRITESYSTEM（读写系统设置权限）：允许应用读写系统设置项</p>
			<p>4)android.permission.ACCESS_WIFI_STATE（获取WiFi状态权限）：监控网络变化，提示用户当前网络环境</p>
			<p>5)android.permission.VIBRATE(使用振动权限）：允许手机振动</p>
			<p>6) android.permission.WAKE_LOCK（唤醒锁定权限）：允许程序在手机屏幕关闭后后台进程仍然运行，保持屏幕唤醒</p>
			<p>7) android.permission.BLUETOOTH（使用蓝牙权限）：蓝牙功能模块使用</p>

			<p style="font-weight: 700;">3.其他第三方SDK获取与用户信息</p>
			<p>为了保证熊猫煤仓APP相关功能的实现与应用安全稳定的运行，外面可能会接入由第三方及相关联方提供的软件开发包（SDK）实现相关的功能目的，我们会对合作方获取信息的软件工具开发包（SDK进行安全检测），以保护数据安全。我们对接入的相关SDK在下方列明。“熊猫煤仓”集成了微信分享等第三方SDK</p>
			<p>3.1集成微信分享</p>
			<p>SDK名称：微信开放平台SDK</p>
			<p>使用目的：方面用户将重要的煤种内容分享给自己的微信好友或发朋友圈</p>
			<p>方式：用户主动点击分享按钮后，会调用微信分享的SDK，选择对应的分享对象，对您浏览的文章详情进行分享</p>
			<p>范围：仅会将当前文章详情的链接通过微信SDK分享，期间我们不会向微信提供任何您的个人信息资料，同时我们也无法获得任何您未授权的微信个人信息</p>
			<p>功能类型：详情页分享功能</p>
			<p>收集个人数据类型：设备信息</p>
			<p>隐私权政策链接及公司：深证市腾讯计算机系统有限公司<a href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s =privacy#1">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s =privacy#1</a></p>
			<p>3.2华为推送SDK</p>
			<p>信息类型：设备标识符 (MEl/Android、ID/Serial/IDFA/OpenUDID/OAID/ICCID/GUID/SIM卡IMSI)、MAC地址、IP地址、位置信息(WLAN接入点、蓝牙）、应用列表信息。</p>
			<p>使用目的：为了向您及时推送服务通知。</p>
			<p>使用场景：在推送消息时使用。</p>
			<p>收集方式：SDK直接本机采集，不涉及数据传输共享</p>
			<p>官网链接：<a href="http://doc.yuntongxun.com/p/5a57159b3b8496dd00ddOceb">http://doc.yuntongxun.com/p/5a57159b3b8496dd00ddOceb</a></p>
			<p>所属公司：华为技术有限公司</p>
			<p>隐私政策链接：<a href="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a></p>
			<p>3.3小米推送SDK</p>
			<p>信息类型：设备标识符 (IMEI/AndroidID/Serial/IDFA/OpenUDID/OAID/ICCID/GUID/SIM卡MSI)、MAC地址、P地址、位置信息(WLAN接入点、蓝牙）、应用列表信息。</p>
			<p>使用目的：为了向您及时推送服务通知。</p>
			<p>使用场景：在推送消息时使用。</p>
			<p>收集方式：sDK直接本机采集，不涉及数据传输共享</p>
			<p>官网链接：<a href="https://dev.mi.com/console/appservice/push.btml">https://dev.mi.com/console/appservice/push.btml</a></p>
			<p>所属公司：小米科技有限责任公司</p>
			<p>隐私政策链接：<a href="https://privacy.mi.com/xiaomicommunity-shar/zh_CN/shar/zh_">https://privacy.mi.com/xiaomicommunity-shar/zh_CN/shar/zh_</a></p>

			<p>3.4 vivo推送SDK</p>
			<p>信息类型：读取外置存储卡；写入外部存储卡；检索正在运行的应用。</p>
			<p>使用目的：为了向您及时推送服务通知。</p>
			<p>使用场景：在推送消息时使用。</p>
			<p>收集方式：SDK直接本机买集，不涉及数据传输共享</p>
			<p>所属公司：维江移动通信有限公司</p>
			<p>隐私政策链接：<a href="https://www.vivo.com.cn/about-vivo/privacy-policy">https://www.vivo.com.cn/about-vivo/privacy-policy</a></p>

			<p>3.5 oppo推送SDK</p>
			<p>信息类型：查看WLAN连接；读取外置存储卡;读取手机状态和身份；写入外部存储卡。</p>
			<p>使用目的：为了向您及时推送服务通知。</p>
			<p>使用场景：在推送消息时使用。</p>
			<p>收集方式：SDK直接本机采集，不涉及数据传输共享</p>
			<p>所属公司：广东欢太科技有限公司</p>
			<p>隐私政策链接：<a href="https://open.oppomobile.com/new/developmentDoc/info?id=10288">https://open.oppomobile.com/new/developmentDoc/info?id=10288</a></p>
			<p>3.6 uniapp的系统消息推送服务<a href="https://uniapp.dcloud.net.cn/unipush-v2.html">https://uniapp.dcloud.net.cn/unipush-v2.html</a></p>
			<p>3.7 个推/个验/个像等SDK，SDK如下：com.igexin.push（个推;个验;个像）、com.g.gysdk（个推;个验;个像）、com.getui.gs（个推;个验;个像）、BSSID,SID个人信息（个推;个验;个像）</p>
			<p>使用目的：APP运行基础模块，提供用户使用各类功能基础</p>
			<p>收集方式：SDK采集</p>
			<p>收集的个人信息类型: 设备标识符(Android如IMEl、AndroidID、OAID、IMSI、ICCID、MEID，ios如IDFV、IDFA) ，MAC地址，IP 地址，位置信息(如GPS、WLAN接入点、蓝牙和基站)</p>
			<p>所属公司/机构：Dcloud 数宁天堂(北京) 网络技术有限公司</p>
			<div class="title">二、用户信息的共享、转让和公开披露</div>
			<p>除以下情况外，我们不会将您的个人信息提供给熊猫煤仓以外的任何个人、公司或组织共享、转让给和公开披露：</p>
			<p>(1）依据本政策或事先已获得您明确的同意或授权；</p>
			<p>(2）根据适用的法律、法规、法律程序的要求、行政或司法的强制性要求所必须时；</p>
			<p>(3）为向您提供完善的产品和服务、提高您使用产品和服务的便利性，在找钢网的关联公司之间共享您的个人信息；</p>
			<p>(4）在法律、法规允许的范国内，为维护熊猫煤仓及关联方或合作伙伴用户或社会公共利益、财产或安全免遭损害时</p>
			<p>(5）据与您签署的相关协议或其他法律文件的约定；在与的关联公司共享您的个人信息的情况下，我们只会共享必要的个人信息，如果我们共享您的敏感个人信息或接收方改变个人信息的使用及处理目的，将再次征求您的授权同意/单独同意。</p>
			<p>您理解并同意，根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人/企业信息主体的，不属手个人信息的对外共享、转让 及公开披露行为，对此类数据的保存及处理将无需另行向您通知井征得您的同意。</p>
			<p>我们的某些服务将由授权合作伙伴提供，仅为实现本隐私政策中声明的目的，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。除另行获得您的授权或法律法规另有要求，我们的合作伙伴无权将共享的个人信息用于任何其他用途。</p>
			<p>关于共享</p>
			<p>除非经过您本人事先单独同意或符合其他法律法规规定的情形，通常情况我们不会向除山西海云启辰公司以外的第三方共享您的个人信息，但经过处理无法识别特定个人旦不能复原的除外。对我们与之共享个人信息的公司、组织和个人，我们会对其数据安全环境进行调查，与其签署严格的保密协定，要求他们按照依法采取保密和安全措施来处理个人信息</p>
			<p>但在下列情況下，我们可能会与以下第三方共享您的个人信息：</p>
			<p>第三方产品或服务提供商。
"熊猫煤仓"移动应用接入了丰富的第三方服务。当您使用"熊猫煤仓"移动应用接入的第三方服务时，可能需要提交您的个人信息。我们将基于您的有效授权或在订立、履行个人作为一方当事人的合同所必需的情况下将您的个人信息提供给为您提供服务的第三方。关于第三方服务具体如何收集、使用您的个人信息，建议您参考第三方服务的相关服务协议及隐私政策。
1当您使用熊猫煤仓应用时，我们可能需要给您进行推送数据，第三方机构比如极光sdk、华为、oppo、vivo等推送sdk会需要获取到您的这些权限：1P地址 AndroidID、Mac地址、应用列表和位置等，目的是为了能够更准确的针对不同区域分类的客户进行消息推送等，当然您可以拒绝这些授权，并不会影响程序的使用。
2当您使用熊猫煤仓应用时，我们可能需要给您进行定位，第三方机构比如高德等地理位置sdk会需要获取到您的这些权限：IP地址 AndroidD、Mac地址、应用列表和位置等，目的是为了能够更准确的识别您的区域和位置等，当然您可以拒绝这些授权，并不会影响程序的使用。</p>
			<div class="title">三、用户个人信息的保护</div>
			<p>3.1我们已采取符合法律规定、合理可行的安全防护措施和制度保护您个人信息的安全，防
止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。本平台将利用加密技术来保护用户提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。用户需要了解，用户接入本平台的服务所用的系统和通讯网络，有可能因本平台可控范围外的因素而出现问题。</p>
			<p>3.2 我们会采取合理可行的措施以避免收集无关的个人信息。我们只会在达成本政策所述目的所需期限内保留您的个人信息，除非需要延长保留期或应法律法规的允许或要求。</p>
			<p>3.3在使用熊猫煤仓服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络熊猫煤仓，以便我们根据您的申请采取相应措施。</p>
			<p>3.4在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。事件相关情况我们将以信函、电话、推送通知方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
			<div class="title">四、用户个人信息的保存、期限、地域、访问和管理</div>
			<p>1.用户个人信息的保存您的个人信息将全部被存储于中华人民共和国境内，但以下情形除外：</p>
			<p>(1）法律法规另有明确规定；</p>
			<p>(2）获得您的明确授杈；</p>
			<p>(3）您通过互联网进行跨境交易个人主动行为;</p>
			<p>针对以上情形，我们会依据本政策及相关法律法规对您的个人信息提供保护。</p>
			<p>2.用户个人信息的保存期限我们将在提供服务所需的期限内保存您的个人信息，但法律法规对保存期限另有规定、您同意留存更长的期限、保证服务的安全与质量、实现争议解决目的等所必需的情况下，在前述保存期限到期后，我们将依法、依约或在合理范围内延长保存期限，在超出保存期限后，我们将根据法律规定删除您的个人信息或进行匿名化处理。</p>
			<p>3.个人信息的保存地域</p>
			<p>原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境
内。如您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问，我们会严格履行法律法规规定的义务并按照法律规定事先获得您的单独同意。此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会按照中国现行法律的规定传输您的个人信息，并会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措</p>
			<p>4.用户个人信息的访问</p>
			<p>您有权通过以下方式访回您的个人信息，但法律法规另有规定，或本政策另有约定除外。
账户信息：若您希望访问或编辑您的账户中的个人基本资料信息，您可登录账号通过"个人中心"执行此类操作。订单信息：您可登录账号通过"我的订单"访问或查看您的订单记录等，若您无法通过上述路径访问该信息，您可通过熊猫煤仓客服与我们联系。</p>
			<p>5.用户个人信息的更正、补充</p>
			<p>您可以在我们的产品/或服务中更正/修改您的相关个人信息。为便于您行使您的上述权利，我们为您提供了在线自行更正/修改申请的方式。</p>
			<p>6.用户个人信息的删除</p>
			<p>一般而言，我们只会在法律法规规定或必需且最短的时间内保存您的个人信息。为便于您行使您的删除权，我们为您提供了在线自行删除的方式。删除路径：我的-设置-清除缓存。删除完成之后当天内生效。
对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以直接进行删除。一旦您删除后，我们即会对此类信息进行删除或匿名化处理，除非法律法规另有规定。</p>
			<p>在以下情形下，您可以直接向我们提出删除您个人信息的请求，但已做数据匿名化处理或法律法规另有规定的除外。</p>
			<p>(1）我们违反法律法规规定，收集、使用您的个人信息的；</p>
			<p>(2）我们违反了与您的约定，收集、使用您的个人信息的</p>
			<p>(3）法律法规等规定的其他情形；</p>
			<p>(4）您不在使用我们产品时。</p>
			<p>为响应您的上述请求力保障安全，您可能需要提供书面请求，我以其他方式证明您的身份我们可能会先雲求您验证自己的导份，然后再处理您的请求。</p>
			<p>7.用户个人信息的注销</p>
			<p>我们为您提供熊猫煤仓账号注销的途径，您可以通过在线申请注销或拨打客服电话进行注销。APP注销路径：我的-设置-注销账号。在您注销熊猫煤仓账号后，该账号在熊猫煤仓下的产品与服务使用期间已产生的但未消耗完华的权益及末来的预期利益全部权益将被清除；该账号下的内容、信息、数据、记录将会被删除或匿名化处理(但法律法规另有规定或监管部门另有费求的除外）：同时熊猫煤仓账号一旦注销完成，将无法恢复。注销申请提交后进入审核阶段，审核通过后最长15个工作日答复处理结果。</p>
			<div class="title">五、Cookie 及类似技术的使用</div>
			<p>1.当用户访问设有 Cookies 装置的本平台时，本平台服务器会自动发送 Cookies 至用户浏览器中，同时储存进用户的电脑硬盘内，此 Cookies便负责记录日后用户访问本平台时的种种操作、浏览消费习惯、信用记录。用户可以随时通过浏览器中自带的清除缓存功能随时删除记录。运用 Cookies 技术，熊猫煤仓能够为您提供更加周到的服务，我们不会进行定向推送服务和广告精准营销。</p>
			<p>2.个性化推荐，应用包含定向推送或广告精准营销功能，为向您展示更契合您个性化推荐的煤种信息，我们会收集您在访问客户端的浏览记录及其他取得您授权的信息，通过您点击的信息特征，匹配推荐您可能感兴趣的煤种进行排序显示</p>
			<div class="title">六、未成年人的特别注意事项</div>
			<p>以下条款请未成年用户（特别是未满十四周岁的儿童用户） 在监护人的陪同下仔细阅读，并由监护人在充分理解后作出是否接受或拒绝本政策的决定：</p>
			<p>1. 若用户是未满18周岁的未成年人（特别是未满十四周岁的儿童用户），应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用熊猫煤仓相关服务。</p>
			<p>2. 我们重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用相关服务 。</p>
			<p>3. 我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据 。同时我们建立了严格的未成年人信息收集使用规则，以保护儿童和青少年个人信息安全。若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您根据本政策第十条提供的联系方式及时与我们联系。</p>
			<div class="title">七、联系方式</div>
			<p>您可以通过以下方式与我们取得联系，如对本政策或相关适宜有任何问题，请通过客服热线13934568608，邮箱haiyunqichen@163.com与本平台联系。</p>
	</div>
</template>
<script>
export default{
    data(){
        return {
			tableData: [
				{
					name: '登录',
					address: '手机号、验证码、微信授权'
          		},
				  {
					name: '发布',
					address: '联系人姓名、联系电话'
          		},
				  {
					name: '联系购买',
					address: '收获地址、收获人电话、收获人姓名、收获人单位'
          		},
				  {
					name: '名片',
					address: '姓名、职位、公司、手机号'
          		},
		   ],
		   time:null,
        }
    },
    created(){

    },
    mounted() {
		let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
		this.time = year + ' 年 ' + month + ' 月 ' + day + ' 日';
    },
    methods:{
       
    },
}
</script>
<style lang="less" scoped>
.comnent{
	width: 100%;
	height: auto;
	padding: 32px 32px 88px;
	box-sizing: border-box;
	background-color: #fff;

}
@media screen and (min-width:750px){
	.tables{
		width: 50%;
	}
	.comh1{
		width: 100%;
		font-size: 48px;
		line-height: 96px;
		color: #333;
		text-align: center;
		margin-bottom: 56px;
		font-weight: 700;
	}
	span{
		font-size: 28px;
		color: #333;
		line-height: 48px;
	}
	p{
		font-size: 28px;
		color: #333;
		line-height: 48px;
	}
	.title{
		font-size: 32px;
		color: #333;
		line-height: 64px;
		font-weight: 600;
	}
	.time{
		width: 100%;
		font-size: 0.4rem;
		color: #333;
		line-height: 0.7rem;
		float: right;
	}
}
@media screen and (max-width:750px) {

	.tables{
		width: 100%;
	}
	.comh1{
		width: 100%;
		font-size: 0.6rem;
		line-height: 1rem;
		color: #333;
		text-align: center;
		margin-bottom: 1rem;
		font-weight: 700;
	}
	span{
		font-size: 0.4rem;
		color: #333;
		line-height: 0.7rem;
	}
	p{
		font-size: 0.4rem;
		color: #333;
		line-height: 0.7rem;
	}
	.title{
		font-size: 0.5rem;
		color: #333;
		line-height: 1rem;
		font-weight: 600;
	}
	.time{
		width: 100%;
		font-size: 0.4rem;
		color: #333;
		line-height: 0.7rem;
		// float: right;
		margin: 0.4rem 0 0 0;
	}
}

</style>